/* Launch css*/
/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:800|Roboto+Condensed:700i"); */

body {
  width: 100%;
  min-height: 600px;
  height: 100%;
  padding: 0;
  margin: 0;
}

#starter {
  z-index: 1;
  position: absolute;
  /* top: 50%;
  left: 50%;
  width: 300px;
  height: 50px;
  margin-top: -25px;
  margin-left: -150px; */
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 3em;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  top: 5rem;
}
#starter img {
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
#starter span {
  background: #fea801;
  color: #ffffff;
  padding: 5px 68px;
  border-radius: 50px;
  top: 5rem;
  position: relative;
}

#scene {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 1200px;
  height: 600px;
  overflow: hidden;
  margin-top: -300px;
  margin-left: -600px;
  /* background-color: rgb(0, 0, 0); */
  background-color: #fea801;
  box-shadow: 0 0 0 2px white inset;
}
#curtain {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

#curtain .left,
#curtain .right {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  filter: brightness(180%);
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/950358/curtain.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

#curtain .left {
  left: 0;
  transform-origin: top right;
}
#curtain .right {
  left: 50%;
  transform-origin: top left;
}

.ground {
  position: absolute;
  left: 50%;
  top: 133%;
  width: 10000px;
  height: 10000px;
  margin-left: -5000px;
  border-radius: 100%;
  box-shadow: 0 0 100px 100px white;
}

#curtain h1 {
  position: absolute;
  left: 30%;
  top: 50%;
  display: block;
  width: 500px;
  height: 150px;
  margin-top: -90px;
  margin-left: -250px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 9em;
  color: #0c1d40;
  transform: scale(0.75);
  opacity: 0;
}

/* **********
	opening
********** */

#scene.expand {
  width: 140%;
  left: -20%;
  margin-left: 0;
  /* background-color: rgb(32, 32, 32); */
  background-color: #fea801;
  box-shadow: 0 0 0 0 white inset;
  animation-fill-mode: forwards;
  animation-name: expand-scene-horizontaly, expand-scene-verticaly;
  animation-duration: 2.5s, 1.5s;
  animation-timing-function: ease-in-out, ease-in-out;
  animation-delay: 0s, 2.5s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
}

#curtain.open .left,
#curtain.open .right {
  filter: brightness(100%);
}
#curtain.open .left {
  animation-fill-mode: forwards;
  animation-name: curtain-opening, left-curtain-opening;
  animation-duration: 2s, 4s;
  animation-timing-function: ease-in-out, ease-in-out;
  animation-delay: 0s, 0s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
}
#curtain.open .right {
  animation-fill-mode: forwards;
  animation-name: curtain-opening, right-curtain-opening;
  animation-duration: 2s, 4s;
  animation-timing-function: ease-in-out, ease-in-out;
  animation-delay: 0s, 0s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
}

#scene.expand .ground {
  animation-fill-mode: forwards;
  animation-name: ground-rising;
  animation-duration: 6s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
}

#scene.expand h1 {
  animation-fill-mode: forwards;
  animation-name: text-zoom, text-fade-in, text-glowing;
  animation-duration: 5s, 1s, 1s;
  animation-timing-function: ease-out, ease-in-out, ease-in-out;
  animation-delay: 3s, 3s, 0s;
  animation-iteration-count: 1, 1, infinite;
  animation-direction: normal, normal, alternate;
}

.fade-out {
  animation-fill-mode: forwards;
  animation-name: fade-out;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
}

/* **********
	animations
********** */

@keyframes expand-scene-horizontaly {
  /* 2.5
	s */
  from {
    width: 1200px;
    left: 50%;
    margin-left: -600px;
    /* background-color: rgb(0, 0, 0); */
    background-color: #fea801;
    box-shadow: 0 0 0 2px white inset;
  }
  to {
    width: 140%;
    left: -20%;
    margin-left: 0;
    /* background-color: rgb(32, 32, 32); */
    background-color: #fea801;
    box-shadow: 0 0 0 0 white inset;
  }
}

@keyframes expand-scene-verticaly {
  /* 1.5s */
  from {
    top: 50%;
    height: 600px;
    margin-top: -300px;
  }
  to {
    top: 0;
    height: 100%;
    margin-top: 0;
  }
}

@keyframes curtain-opening {
  /* 2s */
  from {
    filter: brightness(180%);
  }
  to {
    filter: brightness(100%);
  }
}

@keyframes left-curtain-opening {
  /* 4s */
  from {
    transform: translate(0) rotate(0) scale(1, 1);
  }
  to {
    transform: translate(-100%) rotate(20deg) scale(0, 2);
  }
}

@keyframes right-curtain-opening {
  /* 4s */
  from {
    transform: translate(0) rotate(0) scale(1, 1);
  }
  to {
    transform: translate(100%) rotate(-20deg) scale(0, 2);
  }
}

@keyframes ground-rising {
  from {
    top: 133%;
  }
  to {
    top: 105%;
  }
}

@keyframes text-zoom {
  from {
    transform: scale(0.75);
  }
  to {
    transform: scale(1);
  }
}

@keyframes text-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes text-glowing {
  from {
    text-shadow: 0 0 10px white;
  }
  to {
    text-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px dodgerblue;
  }
}

@keyframes fade-out {
  from {
    /* color: black; */
    color: #0c1d40;
    opacity: 1;
  }
  to {
    color: #0c1d40;
    opacity: 0;
  }
}
